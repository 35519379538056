$(function() {
  var $header = $('#header');

  var $links = $('.js-navigation-sub .js-navigation-link');

  $links.on('click', function(e) {
    if (!$header.hasClass('l-header--navigation-expanded')) {
      // mobile only
      return;
    }

    e.preventDefault();

    var $this = $(this);

    var $sub = $this.siblings('.l-navigation__sub');
    if ($sub.hasClass('l-navigation__sub--visible')) {
      $this.parent().removeClass('l-navigation__item--subexpanded');
      $sub.removeClass('l-navigation__sub--visible');
    }
    else {
      var $subs = $('.l-navigation__sub--visible').removeClass('l-navigation__sub--visible');
      var $links = $subs.siblings('.js-navigation-link');
      $links.parent().removeClass('l-navigation__item--subexpanded');

      $this.parent().addClass('l-navigation__item--subexpanded');
      $sub.addClass('l-navigation__sub--visible');
    }
  });
});
