(function($) {
  $(function () {
    $('.js-volume').on('click', function () {
      if ($('.js-volume-on').is(':visible')) {
        $('.js-volume-on').hide();
        $('.js-volume-off').show();
        $('.js-video').prop('muted', false);
      }
      else {
        $('.js-volume-on').show();
        $('.js-volume-off').hide();
        $('.js-video').prop('muted', true);
      }
    });
  });
})(jQuery);
