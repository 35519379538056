(function($) {
  $(function() {
    var $popin = $('.js-popin');
    if (!$popin.length) {
      return;
    }

    var cookieName = 'popin-' + $popin.data('campaign');

    var now = (new Date()).getTime();
    var lastAppear = getCookie(cookieName + '-last-appear');
    var delayExpired = false;
    if (lastAppear) {
      lastAppear = parseInt(lastAppear);
      var delay = parseInt($popin.data('delay'))*1000;
      var nextAppear = lastAppear + delay;
      if (nextAppear <= now) {
        delayExpired = true;
      }
    }

    if (delayExpired) {
      var status = 'visible';
    }
    else {
      var status = getCookie(cookieName + '-status');
    }

    $popin.find('.c-popin__aside').on('click', function() {
      $popin.toggleClass('is-hidden');
      if (!$popin.hasClass('is-hidden')) {
        setCookie(cookieName + '-last-appear', now, null);
      }
    });
    $popin.find('.c-popin__close').on('click', function() {
      $popin.addClass('is-closed');
      setCookie(cookieName + '-status', 'closed');
      setCookie(cookieName + '-last-appear', now, null);
    });

    if (status == 'hidden') {
      $popin.css('display', 'flex').addClass('is-hidden');
    }
    else if (status == 'closed') {
      $popin.css('display', 'none').addClass('is-closed');
    }
    else {
      $popin.css('display', 'flex');
      setCookie(cookieName + '-last-appear', now, null);
      setCookie(cookieName + '-status', 'hidden');
    }
  });
})(jQuery);

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0; i<ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
