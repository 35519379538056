//= require @easepick/core/dist/index.umd.js
//= require @easepick/datetime/dist/index.umd.js
//= require @easepick/base-plugin/dist/index.umd.js
//= require @easepick/lock-plugin/dist/index.umd.js
//= require @easepick/range-plugin/dist/index.umd.js

(function($) {
  $(function() {
    $('.js-tab-selection').on('click', function() {
      var $this = $(this);
      $this.closest('.l-section').find('.js-tab-selection').removeClass('is-active');
      $this.addClass('is-active');
    });

    var lang = $html.attr('lang') == 'en' ? 'en-US' : 'fr-FR';
    var format = $html.attr('lang') == 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

    $('.js-easepick-inline').each(function() {
      const dates = JSON.parse(this.dataset.dates);
      const min = new Date();
      min.setTime(this.dataset.min + '000');
      const max = new Date();
      max.setTime(this.dataset.max + '000');

      const picker = new easepick.create({
        element: this,
        css: [
          this.dataset.css,
        ],
        format: format,
        lang: lang,
        inline: true,
        setup(picker) {
          picker.on('view', (event) => {
            const { view, target, date } = event.detail;

            if (!date || view !== 'CalendarDay') return;

            const iso_8601 = date.format('YYYY-MM-DD');
            for (const [style, days] of Object.entries(dates)) {
              if (days.includes(iso_8601)) {
                target.classList.add('day-' + style);
                break;
              }
            }
          });
        },
        plugins: ['RangePlugin', 'LockPlugin'],
        LockPlugin: {
          minDate: min,
          maxDate: max,
          inseparable: true,
        }
      });
    });

    $('.js-easepick-input').each(function() {
      const picker = new easepick.create({
        element: this,
        css: [
          this.dataset.css,
        ],
        format: format,
        lang: lang,
        plugins: ['RangePlugin', 'LockPlugin'],
        RangePlugin: {
          tooltipNumber(num) {
            return num - 1;
          },
          locale: {
            one: 'nuit',
            other: 'nuits',
          },
        },
        LockPlugin: {
          minDate: new Date(),
          minDays: 2,
          // maxDays: 22,
          inseparable: true,
        }
      });
    });
  });
})(jQuery);
