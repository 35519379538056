// require locomotive-scroll/bundled/locomotive-scroll.js

// https://scroll.locomotive.ca/demo/
// https://scroll.locomotive.ca/docs/

// (function($) {
//   $(function() {
//     $('.c-parallax').addClass('c-parallax--enabled');

//     const locomotiveScroll = new LocomotiveScroll({
//       lenisOptions: {
//         wrapper: window,
//         // smoothWheel: false,
//         // smoothTouch: false,
//         // normalizeWheel: false,
//       }
//     });
//   })
// })(jQuery);

//= require vendor/_aos.js
(function($) {
  $(function() {
    AOS.init({
      offset: 300,
      duration: 1000,
    });
  });
})(jQuery);
