(function($) {
  $(function() {
    $('.js-auto-submit').on('change', function() {
      $(this).closest('form').submit();
    });

    $('.js-section-switcher select').on('change', function() {
      var value = $(this).val();
      $('.js-section-switched').hide().find('input, select, textarea').prop('disabled', true);
      if (value) {
        $('[id^="section-' + value + '"]').show().find('input, select, textarea').prop('disabled', false);
      }
    }).trigger('change');
  });
})(jQuery);
