//= require slick-carousel/slick/slick.js

$(function() {
  var conf = {
    photos_links: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      fade: true,
      speed: 500,
    },
    text_pictures: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      fade: true,
      speed: 500,
    },
    media: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      fade: true,
      speed: 500,
    },
    quadriptych: {
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: false,
      speed: 500,
      responsive: [
        {
          breakpoint: 650, // jusqu'à 650
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 960, // jusqu'à 960
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 1200, // jusqu'à 1200
          settings: {
            slidesToShow: 3
          }
        },
      ]
    },
  };

  $('.c-slick').each(function() {
    // return;
    var $this = $(this);

    var component = $this.data('slick-component');
    var $component = $this.closest('.c-' + component);
    var slick_class = 'c-' + component + '--slick';
    if ($component.hasClass(slick_class)) return;
    $component.addClass(slick_class);

    $this.slick($.extend({
      dots: false,
      infinite: false,
      speed: 300,
      prevArrow: $component.find('.c-slick__prev'),
      nextArrow: $component.find('.c-slick__next'),
    }, conf[component.replace('-', '_')]));
  })
});
